import React from "react";
import data from "../../hotelData.json";

const propertyImageURL = "";
const sliderImageURL = "";
const instantBookNowUrl = "/common-cgi/package/packagebooking.pl?propertyId=";
const instantBookNowUrl2 =
  "/common-cgi/properties/b2c/Booknow.pl?action=showpage&MotelId=";
const maxBookNowUrl = "/be/indexpackdetail?individual=true&propertyId=";

const followEyeUrl =
  "https://max2uatbe.staah.net/followtheeye/vacationrental/ftelist.php?propertyId=";
const noImageUrl =
  "https://staah.s3.amazonaws.com/images/InstantSiteImages/_w8hRUxla3Z_no-image.png";

const reviewApiAuthorizationKey = "MDgwZjAyNmI6ZjRmZDhjMjU";

// const maxBookNowUrl = "/be/index_be?propertyId=";

const decodeContent = (str) => {
  //console.log('decodeContent----------->',decodeURIComponent(escape(window.atob(str))));
  return decodeURIComponent(escape(window.atob(str)));
};

const bookRoomUrl = (roomCategoryId) => {
  if (data.bookingEngine === "I") {
    return (
      "//" +
      data.whiteLabelURL +
      instantBookNowUrl +
      data.propertyDetails.propertyID
    );
  } else {


    if (data.BEWidgetFlags?.MBS === "Y") {
      return (
        "//www.swiftbook.io/inst/#home?propertyId=" +
        data.propertyDetails.staahPropertyIDEnc+"&RoomID="+roomCategoryId+"&JDRN=Y"
      );
    }
    if (data.BEWidgetFlags.followTheEye === "Y") {
      return (
        "//max2uatbe.staah.net/followtheeye/vacationrental/ftelist.php?propertyId=" +
        data.BEWidgetFlags.FTEKey
      );
    } else {
      return (
        "//" +
        data.whiteLabelURL +
        "/be/indexpack?individual=true&roomTypeId=" +
        roomCategoryId +
        "&propertyId=" +
        data.propertyDetails.propertyID
      );
    }
  }
};

const bookNowUrl = (flexi) => {
  if (data.bookingEngine === "I") {
    return (
      "//" +
      data.whiteLabelURL +
      (flexi === "1" ? instantBookNowUrl2 : instantBookNowUrl) +
      data.propertyDetails.propertyID
    );
  } else {
     
    if (data.BEWidgetFlags?.MBS === "Y") {
      return (
        "//www.swiftbook.io/inst/#home?propertyId=" +
        data.propertyDetails.staahPropertyIDEnc+"&JDRN=Y"
      );
    } 
    if (data.BEWidgetFlags.followTheEye === "Y") {
      return (
        "//max2uatbe.staah.net/followtheeye/vacationrental/ftelist.php?propertyId=" +
        data.BEWidgetFlags.FTEKey
      );
    }
    else {
      return (
        "//" +
        data.whiteLabelURL +
        maxBookNowUrl +
        data.propertyDetails.propertyID
      );
    }
  }
};

function WidgetOpen(value) {
  if (value === true) {
    const bookingWidgetMain = document.getElementById("bookingWidgetMain");
    bookingWidgetMain.classList.remove("default");
    bookingWidgetMain.classList.remove("widgetClose");

    setTimeout(() => {
      bookingWidgetMain.classList.add("widgetOpen");
    }, 100);
  }
}
function WidgetClose() {
  const bookingWidgetMain = document.getElementById("bookingWidgetMain");
  const widgetCloseButton = document.getElementById("widgetCloseButton");
  widgetCloseButton.addEventListener("click", () => {
    bookingWidgetMain.classList.add("widgetClose");
    bookingWidgetMain.classList.remove("widgetOpen");
    setTimeout(() => {
      bookingWidgetMain.classList.add("default");
    }, 850);
  });
}

function randomImageGenerator(images){
  const imageLength = images && images.length > 0 ? images.length : 0;
  let randomImageUrl = "";
if (imageLength > 0) {
  var randomNumber = Math.floor(Math.random() * imageLength) ;
    randomImageUrl = images[randomNumber];
}
console.log(randomNumber);

return randomImageUrl;
} 


export {
  propertyImageURL,
  sliderImageURL,
  noImageUrl,
  reviewApiAuthorizationKey,
  bookNowUrl,
  instantBookNowUrl,
  maxBookNowUrl,
  decodeContent,
  bookRoomUrl,
  followEyeUrl,
  WidgetOpen,
  WidgetClose,
  randomImageGenerator,
};
